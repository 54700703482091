import React, { useState } from 'react';
import axios from 'axios';
import { CSSProperties } from 'react';
// import poza from './photos/poza.svg'

const poza = require('./photos/poza.jpg')

axios.defaults.baseURL ="https://absolvire.vercel.app/"

const HomePage: React.FC = () => {
  const [message, setMessage] = useState<string>('');
  const [username, setUsername] = useState<string>('');

  const fetchMessage = async () => {
    try {
        const storedUsername = localStorage.getItem('username');
        const data = {
            username: storedUsername
        }
      const response = await axios.get('/message', {headers : {
        "x-username": storedUsername
      }}); // Adjust the endpoint as needed
      setMessage(response.data.message); // Assuming the message is stored with the "message" tag in the response
    } catch (error) {
      console.error('Error fetching message', error);
    }
  };

  const homeContainerStyle: CSSProperties= {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    position: 'relative',
    backgroundImage: `url(${poza})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    zIndex:1
  };

  const photoStyle: CSSProperties = {
    zIndex: 1
  };

  const buttonStyle: CSSProperties = {
    marginTop: '20px',
    backgroundColor:"transparent",
    border:"2px solid white",
    borderRadius:'10px',
    color:"white",
    zIndex : 1,
    cursor:"pointer"
  };
  const messageStyle: CSSProperties = {
    zIndex: 1,
    font: 'bold',
    color:"white",
    textAlign:"center"
  }

  const blurOverlayStyle: CSSProperties = {
    ...homeContainerStyle,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'blur(10px)',
    zIndex: 0,
    height:"auto"
  };

  return (
    <div style={homeContainerStyle}>
      <div style={photoStyle}>
        <img src={poza} style={{ width: '100%', height: 'auto' }} />
      </div>
      
      <button style={buttonStyle}onClick={fetchMessage}>Show Message</button>
      {message && <p style={messageStyle}>{message}</p>}
      <div style={blurOverlayStyle} />
    </div>
  );
};

export default HomePage;
