import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CSSProperties } from 'react';
const poza = require('./photos/poza2.jpg')
 
const baseURL = "https://absolvire.vercel.app/";
axios.defaults.baseURL = baseURL;

console.log(baseURL);


interface LoginProps {}

const LoginPage: React.FC<LoginProps> = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const { data } = await axios.post('/login', { username, password });
      localStorage.setItem('username', username);
    console.log('login success');
    navigate('/home');

    } catch (error) {
      alert('Invalid credentials');
    }
  };

  const loginContainer: CSSProperties = {
    height:'100vh',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundImage: `url(${poza})`,
    backgroundSize:'cover',
    backgroundPosition:'center',
    zIndex:0,
    overflow:'hidden'
  };

  const loginBox: CSSProperties ={
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    gap:'10px',
    backgroundColor:'black',
    padding:'20px',
    borderRadius:'10px',
    zIndex:1,
    width:"50vw",
    maxWidth: "300px"

  }

  const loginButton: CSSProperties = {
    marginTop: '20px',
    backgroundColor:"transparent",
    border:"2px solid white",
    borderRadius:'10px',
    color:"white",
    zIndex : 1,
    cursor:"pointer"
  }
  const blurOverlayStyle: CSSProperties = {
    ...loginContainer,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    filter: 'blur(15px)',
    zIndex: 0,
    height:"auto"
  };

  return (
    <div style={loginContainer}>
      <div style={loginBox}>
        <input style={{borderRadius:'10px'}} type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        <input style={{borderRadius:'10px'}} type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button style={loginButton} onClick={handleLogin}>Logare</button>
      </div>
      

      <div style={blurOverlayStyle}></div>
    </div>
  );
};

export default LoginPage;
